import "core-js/modules/es.array.push.js";
import { showDialog } from 'vant';
import { get } from './../api';
import TWEEN from "@tweenjs/tween.js";
import yhPic from './../assets/yh.png';
export default {
  components: {},
  data() {
    return {
      urls: ['river', 'dialect', 'interact'],
      offset: {
        x: 50,
        y: -50
      },
      post: {},
      loading: true,
      puzzle: [],
      posList: [],
      selectPic: {},
      to: '',
      assetsUrl: window.assetsUrl,
      width: '',
      height: '',
      drag: '',
      // start   stop
      selectIndex: -1,
      toIndex: -1,
      moveTimes: 0,
      cols: 3,
      rows: 3,
      scaleY: 1,
      // 三行三列 1  二行三列 高度 * 1.5
      block_w: '',
      block_h: '',
      startX: 0,
      startY: 0
    };
  },
  mounted() {
    document.title = '知识互动';
    window.addEventListener('resize', () => {
      this.getWindowInfo();
    });
    this.getWindowInfo();
    this.getHome();
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.getWindowInfo();
    });
  },
  methods: {
    myTween(toObj, fromObj, currentIndex = -1) {
      let vm = this;
      const animate = function () {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      };
      new TWEEN.Tween(fromObj).to(toObj, 200).onUpdate(object => {
        let pic = vm.puzzle[currentIndex];
        console.log(currentIndex);
        pic.zIndex = 10;
        pic.top = object.top;
        pic.left = object.left;
        vm.puzzle.splice(currentIndex, 1, pic);
      }).start().onComplete(() => {
        vm.moveTimes++;
        if (vm.moveTimes == 2) {
          vm.swap(vm.selectIndex, vm.toIndex);
        }
        console.log('tween end..');
      });
      animate();
    },
    handleTouchMove(e) {
      this.handleMouseMove(e);
    },
    handleTouchStart(e) {
      this.handleClick(e);
    },
    formatPuzzle() {
      this.posList = [];
      this.block_w = (this.width * 0.75 - 160) / 3;
      this.block_h = this.block_w * 900 / 1600; // 3 行的高度
      let cols = this.cols;
      if (this.puzzle.length == 6) {
        this.block_h = this.block_h * 1.5; // 2 行的高度
        this.rows = 2;
      } else {
        this.rows = 3;
      }
      this.puzzle.sort(() => Math.random() - 0.5);
      this.puzzle.forEach((item, index) => {
        item.left = index % cols * this.block_w;
        item.zIndex = 6;
        item.top = Math.floor(index / cols) * this.block_h;
        this.posList.push({
          left: item.left,
          top: item.top,
          zIndex: 6
        });
      });
      this.loading = false;
      this.drag = '';
    },
    swap(from, to) {
      let from_puzzle = Object.assign({}, this.puzzle[from]);
      let to_puzzle = Object.assign({}, this.puzzle[to]);
      from_puzzle = Object.assign(from_puzzle, this.posList[to]);
      to_puzzle = Object.assign(to_puzzle, this.posList[from]);
      this.puzzle.splice(to, 1, from_puzzle);
      this.puzzle.splice(from, 1, to_puzzle);
      this.selectIndex = -1;
      this.toIndex = -1;
      this.moveTimes = 0;
      //检查是否完成
      let success = this.checkPuzzle();
      this.drag = success ? 'end' : '';
    },
    checkPuzzle() {
      let success = true;
      this.puzzle.forEach((item, index) => {
        if (item.key != index) {
          success = false;
        }
      });
      console.log('checkPuzzle', success);
      if (success) {
        showDialog({
          title: '恭喜您,拼图完成!',
          message: `<img src="${yhPic}"' style="margin:auto;"/>`,
          confirmButtonText: '开 始 拼 图',
          confirmButtonColor: '#6196e6',
          theme: 'round-button',
          allowHtml: true
        }).then(() => {
          this.getHome();
        });
      }
      return success;
    },
    //点击开始 拿起 或 放
    handleClick(e) {
      if (this.drag == 'start') {
        // 放
        this.drag = 'stop';
        this.dropPic(this.puzzle[this.selectIndex]);
        console.log('放...');
        return true;
      } else if (this.drag == '') {
        this.drag = 'start';
        let index = e.target.dataset.index;
        this.selectIndex = index;
        if (e.type == 'touchstart') {
          this.startX = 0;
          this.startY = 0;
        } else {
          this.startX = e.layerX || '';
          this.startY = e.layerY || '';
        }
        this.selectPic = this.puzzle[index];
        console.log('拿起', index, this.drag);
      }
    },
    handleMouseUp() {
      console.log('mouse up..');
    },
    handleTouchEnd() {
      console.log('handleTouchEnd..');
      if (this.drag == 'start') {
        // 放
        this.drag = 'stop';
        this.dropPic(this.puzzle[this.selectIndex]);
      }
    },
    handleMouseLeave() {
      console.log('mouse leave..');
      if (this.drag == 'start') {
        // 放
        this.drag = 'stop';
        this.dropPic(this.puzzle[this.selectIndex]);
      }
    },
    dropPic(pic) {
      this.drag = 'swap';
      let x = Math.round(pic.left / this.block_w);
      let y = Math.round(pic.top / (this.block_h * this.scaleY));
      let index = y * this.cols + x; //目标

      // 不移动 返回原位置
      if (index >= this.posList.length) {
        index == this.selectIndex;
      }
      let to = Object.assign({}, this.posList[index]);
      let from = {
        left: pic.left,
        top: pic.top
      };
      this.myTween(to, from, this.selectIndex); //移动到指定位置
      let to2 = Object.assign({}, this.posList[this.selectIndex]); //换位
      this.toIndex = index;
      this.myTween(to2, to, index);
    },
    handleMouseMove(e) {
      if (this.drag != 'start') {
        return true;
      }
      if (!this.startX && !this.startY) {
        this.startX = e.touches[0].clientX;
        this.startY = e.touches[0].clientY;
        return true;
      }
      let layerX = e.touches[0].clientX;
      let layerY = e.touches[0].clientY;
      let pic = this.puzzle[this.selectIndex];
      console.log(pic.left, layerX - this.startX);
      let old_pos = this.posList[this.selectIndex];
      pic.left = old_pos.left + layerX - this.startX;
      pic.top = old_pos.top + layerY - this.startY;
      if (pic.left < 0) {
        pic.left = 0;
      }
      if (pic.left > (this.cols - 1) * this.block_w) {
        pic.left = (this.cols - 1) * this.block_w;
      }
      if (pic.top < 0) {
        pic.top = 0;
      }
      if (pic.top > (this.rows - 1) * this.block_h) {
        pic.top = (this.rows - 1) * this.block_h;
      }
      pic.zIndex = 10;
      console.log(layerX, layerY, this.startX, this.startY);
      this.puzzle.splice(this.selectIndex, 1, pic);
    },
    getWindowInfo() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    back() {
      this.$router.push('interact');
    },
    toUrl(to) {
      this.to = to;
      setTimeout(() => {
        this.$router.push(to);
      }, 300);
    },
    getHome() {
      get('puzzle', {
        id: this.post.id || 0
      }).then(res => {
        console.log(res);
        this.post = res.data.post;
        this.puzzle = res.data.parts;
        this.formatPuzzle();
      });
    }
  }
};